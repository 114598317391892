import React, { Component } from 'react';

export class Home extends Component {
  static displayName = Home.name;

  render() {
    return (
      <div>
        <h1>Emily Hommerding</h1>
            <p>I am an accomplished software engineer focused on data and analytics, with strong critical-thinking skills and a determination to get to the bottom of tough problems. I am passionate about facilitating collaboration across teams and stakeholders, while also bringing my unique background to the table.</p>
        <h2>Experience</h2>
            <p><strong>Software Engineer II / Microsoft </strong>, Redmond, WA / July 2017 to present</p>
            <ul>
                <li>Power Apps App Insights Team</li>
                <ul>
                    <li>Served as Data Champ in the Power Apps Enterprise Platform group, driving data and analytics improvements and serving as a subject matter expert across teams in the organization.</li>
                    <li>Implemented telemetry and monitoring in Power Apps across several services and wrote Cosmos DB and SQL scripts to analyze usage. </li>
                    <li>Created Power BI dashboards to contribute to business intelligence, and drive performance and reliability improvements.</li>
                </ul>
                <li>Power Apps Language Team</li>
                <ul>
                    <li>Implemented new functions in the Power Apps language using C# and Typescript. </li>
                    <li>Served on a 24/7 on-call rotation to support web services. </li>
                    <li>Worked closely with developers at the Red Cross to create Power Apps applications at the height of the pandemic.</li>
                </ul>
                <li>Dynamics 365 Telemetry Team</li>
                <ul>
                    <li>Developed a visual and interactive server health monitoring tool with Unity. </li>
                    <li>Worked on Azure services and made improvements to CI/CD pipelines. </li>
                </ul>
                <li>Volunteering: Co-lead of the Women in Power Apps group, mentored interns in the first generation and low-income group at Microsoft, volunteered as a lab instructor for Ignite Worldwide coding events for girls, mentored 30 interns as a counselor in the Camp Microsoft program.</li>
            </ul>

            <p><strong>Laboratory Physicist / Research International,</strong>, Monroe, WA / July 2016 to July 2017</p>
            <ul>
                <li>Independently developed an Android application for use in a chemical detection device using Java and Android Studio. Product is patented and has been in production internationally since 2017.</li>
                <li>Used OpenCV to conduct image analysis of chemical test strips.</li>
                <li>Wrote firmware for a CPU microcontroller for use in a battery monitoring circuit. Utilized best-practices for systems-level programming, conducted analog to digital conversion, and used USB and RS-232 communication protocols.</li>
            </ul>

            <h2>Education</h2>
            <p>M.S. Physics / University of Oregon / Eugene, OR / June 2016</p>
            <p>B.S. Physics Magna Cum Laude / Illinois Institute of Technology / Chicago, IL / May 2013</p>

            <h2>Patents and Publications</h2>
            <ul>
                <li><a href="https://patentimages.storage.googleapis.com/4b/e9/4a/507d0bee3b99da/US20170300779A1.pdf">US Patent US20170300779A1</a></li>
                <li><a href="https://journals.aps.org/prab/abstract/10.1103/PhysRevSTAB.16.064701">American Physical Society publication: Detection of surface carbon and hydrocarbons in hot spot regions of niobium superconducting rf cavities by Raman spectroscopy.</a></li>
            </ul>

            <h2>Technology</h2>
            <p>Current: C#, SQL, Azure, Cosmos DB, Kusto, Power BI, Python</p>
            <p>Past: Java, Android development, Unity, C++, C</p>
      </div>
    );
  }
}

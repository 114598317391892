import React, { Component } from 'react';

export class Contact extends Component {
  static displayName = Contact.name;

  constructor(props) {
    super(props);
    this.state = { currentCount: 0 };
    this.incrementCounter = this.incrementCounter.bind(this);
  }

  incrementCounter() {
    this.setState({
      currentCount: this.state.currentCount + 1
    });
  }

  render() {
    return (
      <div>
        <h1>Contact</h1>

            <p><a href="https://www.linkedin.com/in/emily-hommerding-36390620/">LinkedIn</a></p>
            <p><strong>Email:</strong> emhomm4@gmail.com</p>

        {/*<p aria-live="polite">Current count: <strong>{this.state.currentCount}</strong></p>*/}

        {/*<button className="btn btn-primary" onClick={this.incrementCounter}>Increment</button>*/}
      </div>
    );
  }
}
